<template>

<router-link :to="{name: route}" class="button" :class="{'is-active': active}">

	<app-icon :icon="icon" class="button-icon" />
	
	<div class="button-text">{{ text }}</div>

</router-link>

</template>

<script>

export default {

	props: ['text', 'route', 'icon'],

	computed: {

		active: function() {

			return this.route !== undefined && (this.route === this.$route.name || this.route === this.$route.meta.parent)

		}

	}

}

</script>

<style scoped>

.button {
	display: flex;
	flex-direction: row;
	min-height: 40px;
	margin: 0px 10px;
	align-items: center;
	color: #fff;
	background-color: #4082d3;
	padding: 0px 10px;
	border-radius: 12px;
	margin-top: 20px;
	cursor: pointer;
}

.button:hover {
	background-color: #236dc9;
	color: #fff;
}

.button.is-active {
	background-color: #ffbe7a;
	color: #4d5b6e;
}

.button-icon {
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	flex-shrink: 0;
	font-size: 20px;
	margin-right: 10px;
}

.is-mobile .button-icon {
	font-size: 20px;
}

.button-text {
	font-size: 16px;
	font-weight: 300;
	flex-grow: 1;
	letter-spacing: 1px;
}

.is-mobile .button-text {
	font-size: 14px;
}

</style>
